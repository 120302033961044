<template>
  <v-text-field
    placeholder="Search"
    class="mt-3 mr-8 search-field"
    hide-details
    style="max-width: 220px"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
      <v-btn
        class="mt-n2 ml-n2 search-btn"
        fab
        small
        elevation="0"
        height="44"
        width="44"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'DefaultSearch'
}
</script>
